import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { CONTENT_ROUTES } from './shared-web/routes/content-layout.routes';
import { ContentLayoutComponent } from './core-web/layouts/content/content-layout.component';

const appRoutes: Routes = [
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
