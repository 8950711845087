import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  count = 0;
  private loaderSubject = new Subject<boolean>();
  public loaderState = this.loaderSubject.asObservable();

  constructor() { }

  show() {
    this.count++;
    if (this.count > 0) { this.loaderSubject.next(true); }
  }

  hide() {
    if (this.count > 0) { this.count--; }
    if (this.count === 0) { this.loaderSubject.next(false); }
  }
}
