import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { tap } from 'rxjs/internal/operators/tap';
import { finalize } from 'rxjs/internal/operators/finalize';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(
        public toastr: ToastService,
        private loaderService: LoaderService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let ok: string;
        const token = JSON.parse(localStorage.getItem('smod-admin-token'));

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: token ? token : ''
        });

        const foreignUrl = req.url.indexOf('upload') > -1;

        if (foreignUrl) {
            headers = new HttpHeaders({
                Authorization: token ? token : ''
            });
        }

        const clonedReq = req.clone({ headers });
        return next.handle(clonedReq)
            .pipe(
                tap(
                    event => { ok = event instanceof HttpResponse ? 'succeeded' : ''; },
                    error => { ok = error, this.onSubscribeError(ok); }
                    // Log when response observable either completes or errors
                ),
                finalize(() => {
                    this.loaderService.hide();
                })
            );
    }

    private onSubscribeError(error: any) {
        const message = error.error.message;
        this.toastr.danger(message, 'Error!');
    }
}
