import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2IzitoastService } from 'ng2-izitoast';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    Ng2IzitoastService
  ]
})
export class CoreModule { }
