import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';

const exportModules = [
  RouterModule,
  ReactiveFormsModule,
  FormsModule,
  NgbModule,
  CommonModule,
  HttpClientModule,
  NgSelectModule,
];

@NgModule({
  imports: [
    ...exportModules
  ],
  exports: [
    ...exportModules
  ]
})
export class SharedModule { }
