import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [],
  exports: [
    SharedModule
  ],
  imports: [
    SharedModule
  ]
})
export class SharedWebModule { }
